import BR from 'primitives/Layout/BR';
import CardsContainer from './primitives/CardsContainer';
import Caret from 'components/ShowMoreTiles/icons/Caret';
import checkAutoplay from 'web-player/autoplay';
import DummyCard from '../DummyCard';
import Empty from './Empty';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PodcastCard from '../PodcastCard';
import ShouldShow from 'components/ShouldShow';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import ViewTransition from 'primitives/ViewTransition';
import { Episode } from 'state/Podcast/types';
import { get, isEqual } from 'lodash-es';
import { getCurrentPodcastEpisodes } from 'state/Podcast/selectors';
import { PlayerState } from 'state/Playback/types/PlayerState';
import { next as playNext } from 'components/Player/PlayerActions/shims';
import { STATION_TYPE, StationType } from 'constants/stationTypes';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export type Props = {
  currentlyPlaying: {
    mediaId: number;
    playedFrom: string;
    seedId: number | string;
    stationTrackId: number;
    stationType: StationType;
  };
  episodes: Array<Episode>;
  getPodcastEpisodesWithAuth: (
    seedId: number,
    limit: number,
    nextPageKey: string | null,
    episodeOrder: string,
  ) => void;
  sortAsc: boolean;
  playingState: PlayerState;
  showPodcastTranscriptions?: boolean;
  seedId: number;
  stationLoaded: boolean;
  title?: string;
};

const DEFAULT_PAGE_LIMIT = 5;

function PodcastEpisodeCards({
  currentlyPlaying,
  getPodcastEpisodesWithAuth,
  playingState,
  seedId,
  showPodcastTranscriptions,
  sortAsc,
  stationLoaded,
}: Props) {
  const episodes = useSelector(getCurrentPodcastEpisodes, isEqual);
  const [hasReceivedEpisodes, setHasReceivedEpisodes] = useState<boolean>(
    episodes.length > 0,
  );
  const [nextPageKey, setNextPageKey] = useState<string | undefined>(undefined);
  const [pageLimit, setPageLimit] = useState<number>(DEFAULT_PAGE_LIMIT);
  const [sortedEpisodes, setEpisodes] = useState<Array<Episode>>(
    episodes || [],
  );
  const [currentSeedId, setCurrentSeedId] = useState<number>(seedId);
  const translate = useTranslate();

  // when called, this will fetch episodes to be populated in store
  // useCallback is used because since we're fetching, this is an expensive
  // function. useCallback allows us to memoize the function, and it'll only
  // rerun when called or if episodeOrder is changed
  const fetchEpisodes = useCallback(
    async (fetchPageKey, fetchPageLimit) => {
      const sortBy = sortAsc ? 'startDate-asc' : 'startDate-desc';
      const response = await getPodcastEpisodesWithAuth(
        seedId,
        fetchPageLimit,
        fetchPageKey,
        sortBy,
      );
      const next = get(response, ['links', 'next'], undefined);
      setNextPageKey(next);
      setHasReceivedEpisodes(true);
    },
    [seedId, sortAsc],
  );

  // fetch episodes when seedId changes
  useEffect(() => {
    if (seedId !== currentSeedId) {
      setCurrentSeedId(seedId);
      setHasReceivedEpisodes(false);
    }
    fetchEpisodes(undefined, DEFAULT_PAGE_LIMIT);
  }, [seedId, sortAsc]);

  // when new episodes come in, we sort them and only set the episodes allowed in page limit
  useEffect(() => {
    const sorted = hasReceivedEpisodes
      ? episodes
          .sort((a, b) => {
            const aStartDate = a?.startDate;
            const bStartDate = b?.startDate;

            if (sortAsc) {
              return aStartDate > bStartDate ? 1 : -1;
            }
            return aStartDate <= bStartDate ? 1 : -1;
          })
          .slice(0, pageLimit)
      : episodes;
    setEpisodes(sorted);
  }, [episodes, hasReceivedEpisodes]);

  // when we get a new episode order from props, reset the episodes state and page limit
  // NOTE: we have to do this because 'pageKey' is only for a consistent pageLimit (must consistently)
  // pull 5 episodes at a time for having 'pageKey' work properly. So if we reverse the order, even if we pull
  // the same amount of episodes that are currently in 'pageLimit' (let's say 30), so the 'pageKey' returned won't be able
  // to be used to return the next 5 episodes if show more is clicked. So, we have to reset back to 5 episodes and
  // and restart from there in order to be sconsistent
  useEffect(() => {
    if (hasReceivedEpisodes) {
      fetchEpisodes(undefined, DEFAULT_PAGE_LIMIT);
      setPageLimit(DEFAULT_PAGE_LIMIT);
    }
  }, [sortAsc]);

  // if station loaded, then checkAutoplay!
  useEffect(() => {
    if (stationLoaded) {
      checkAutoplay({
        seedId,
        seedType: STATION_TYPE.PODCAST,
      });
    }
  }, [seedId, stationLoaded]);

  return (
    <>
      <ShouldShow shouldShow={!hasReceivedEpisodes}>
        <ViewTransition>
          <CardsContainer>
            <DummyCard isExpanded />
            <DummyCard />
            <DummyCard />
            <DummyCard />
            <DummyCard />
          </CardsContainer>
        </ViewTransition>
      </ShouldShow>

      <ShouldShow shouldShow={hasReceivedEpisodes && episodes.length === 0}>
        <Empty />
      </ShouldShow>

      <ShouldShow shouldShow={hasReceivedEpisodes && episodes.length > 0}>
        <CardsContainer>
          <div>
            {sortedEpisodes.map((episode, i) => (
              <ViewTransition key={`podcast-card-${episode.id}`}>
                <PodcastCard
                  currentlyPlaying={currentlyPlaying}
                  episodeDuration={episode.duration}
                  isInitiallyExpanded={i === 0}
                  isNewEpisode={episode.new}
                  pageName="podcast_profile"
                  playingState={playingState}
                  playNext={playNext}
                  showPodcastTranscriptions={
                    episode?.transcriptionAvailable && showPodcastTranscriptions
                  }
                  {...episode}
                />
              </ViewTransition>
            ))}
          </div>

          <BR />

          <ShouldShow shouldShow={!!nextPageKey}>
            <OutlinedButton
              onClick={() => {
                fetchEpisodes(nextPageKey, DEFAULT_PAGE_LIMIT);
                setPageLimit(pageLimit + DEFAULT_PAGE_LIMIT);
              }}
            >
              {translate('See More')}
              <Caret direction="down" />
            </OutlinedButton>
          </ShouldShow>
        </CardsContainer>
      </ShouldShow>
    </>
  );
}

export default PodcastEpisodeCards;
