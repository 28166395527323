import styled from '@emotion/styled';

type Props = {
  showContent?: boolean;
};

const ViewTransition = styled('div')<Props>(
  ({ showContent = true, theme }) => ({
    animation: `${theme.keyframes.fadeIn} ease-in-out 0.3s forwards`,
    opacity: 0,

    ...(showContent
      ? {}
      : {
          animation: `${theme.keyframes.fadeOut} ease-in-out 0.3s forwards`,
          opacity: 1,
        }),
  }),
);

export default ViewTransition;
